import React from 'react'
import { graphql } from 'gatsby'
import {
    Container,
    ContentSpacer,
    Layout,
    LocaleConfig,
    PageTitle,
    SEO,
    usePageSections,
    useTranslations,
} from '../modules/core'
import styled from 'styled-components'
import { BrowserSupportTable } from '../modules/browser-support/BrowserSupportTable'

type SystemRequirementsSection = 'supportedDevices' | 'update'

export interface SystemRequirementsPageData {
    allMarkdownRemark: {
        edges: Array<{
            node: {
                frontmatter: {
                    section: SystemRequirementsSection
                    title: string
                    locale: LocaleConfig['locale']
                }
                html: string
            }
        }>
    }
}

export const query = graphql`
    query SystemRequirementsQuery($locale: String) {
        allMarkdownRemark(
            filter: {
                frontmatter: {
                    page: { eq: "system-requirements" }
                    locale: { eq: $locale }
                }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        section
                        title
                    }
                    html
                }
            }
        }
    }
`

interface SystemRequirementsPageProps {
    path: string
    pageContext: {
        localeConfig: LocaleConfig
    }
    data: SystemRequirementsPageData
}

const SystemRequirementsPage = ({
    path,
    pageContext,
    data,
}: SystemRequirementsPageProps) => {
    const sections = usePageSections<SystemRequirementsSection>(data)
    const { translate } = useTranslations(pageContext.localeConfig.locale)

    return (
        <Layout
            locale={pageContext.localeConfig}
            path={path}
            hasContactSection={false}
        >
            <SEO title={translate('page_system-requirements')} />
            <PageTitle>{translate('page_system-requirements')}</PageTitle>
            <Container>
                <Content
                    dangerouslySetInnerHTML={{
                        __html: sections.supportedDevices.content,
                    }}
                />
                <ContentSpacer />
                <BrowserSupportTable locale={pageContext.localeConfig} />
                <ContentSpacer size="large" />
                <Content
                    dangerouslySetInnerHTML={{
                        __html: sections.update.content,
                    }}
                />
                <ContentSpacer size="large" />
            </Container>
        </Layout>
    )
}

export default SystemRequirementsPage

const Content = styled.div``
